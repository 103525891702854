import { OnChatBotClient } from "@gameon/web/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import ContactForm from "../../components/contact-form/contact-form";
import Decor from "../../components/decor/decor";
import HeroText from "../../components/hero-text/hero-text";
import LastWords from "../../components/last-words/last-words";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Stats from "../../components/stats/stats";
import TestimonyQuote from "../../components/testimony-quote/testimony-quote";

import "./case-study.scss";

const IS_BROWSER = typeof window !== "undefined";

class CaseStudy extends React.Component {
  render() {
    return (
      <div className="case-study-page case-study-page--nba theme-red">
        <Layout>
          {IS_BROWSER && (
            <OnChatBotClient
              clientId={"497e8997-ef0b-4abf-95cd-a41211b447e5"}
              displayMode="WIDGET"
            />
          )}

          <HeroText>
            <StaticImage
              className="hero-text__background--mobile"
              alt=""
              src="../../../static/images/hero-text/nba-case-study-header.jpg"
              placeholder="blurred"
              quality={100}
              layout={"fullWidth"}
            />
            <StaticImage
              className="hero-text__background--computer"
              alt=""
              src="../../../static/images/hero-text/nba-case-study-header.jpg"
              placeholder="blurred"
              quality={100}
              layout={"fullWidth"}
            />
            <section className="hero-text__text">
              <div className="hero-text__content">
                <div className="hero-text__title heading-1">NBA</div>
                <p className="hero-text__caption heading-6">
                  GameOn’s conversational AI platform delivers a new channel for
                  the NBA to engage with their global fanbase.
                </p>
              </div>
            </section>
          </HeroText>

          <div className="text-content">
            <h2 className="heading-3">
              Fan engagement anywhere and at anytime
            </h2>
          </div>

          <Decor type="medium" />
          <Decor type="rotate--counter-clockwise" />

          <div className="text-content">
            <p className="heading-6">
              The{" "}
              <a className="underline--inline" href="https://www.nba.com/">
                National Basketball Association (NBA)
              </a>{" "}
              consists of 30 teams across North America and features the best
              basketball players and athletes in the world. The NBA has a large
              and diverse fan base with ongoing and active content from latest
              news and highlights to team and player stats as well as standings
              and rankings. The league's fan-driven topics span across many
              players and teams with multiple games being played per day.
            </p>
            <p className="heading-6">
              The NBA wanted to establish a new, more personal touchpoint for
              their global fan base and create an environment where their wealth
              of curated knowledge would be more accessible to convert casual
              users into prominent consumers.
            </p>
            <div className="heading-4">
              Tailored experiences for a global fanbase
            </div>
            <p className="body-text">
              The NBA partnered with the GameOn Labs team, a fully managed
              partner support arm, to develop a digital-first business strategy
              and deliver a tailored conversational AI solution that provides a
              robust and personalized audience experience. They wanted users to
              be able to specify topics they would like to receive notifications
              on, allowing users to refine information that is most relevant to
              their interests and engage with more timely content.
            </p>
          </div>

          <div className="align-right">
            <Decor type="short" />
          </div>

          <Stats
            left={{
              metric: "1M+",
              heading: "Messages received in < 1mo",
              description:
                "Within a month of launch, the NBA had received over 1 million messages from fans on Facebook Messenger.",
            }}
            right={{
              metric: "20M+",
              heading: "Messages sent in 2019-20",
              description:
                "During the 2019-20 season, fans relied on the messaging app for the latest news and and Covid related updates.",
            }}
          />
          <Stats
            left={{
              metric: "Dunks",
              heading: "Delivered 30K times",
              description:
                "Popular with fans to use multiple entities in dialogues to request “Dunk Videos” from their favorite players.",
            }}
            right={{
              metric: "60%",
              heading: "Growth in engagement",
              description:
                "Hours of video highlights watched through the conversational app grew 60% month over month during the season.",
            }}
          />

          <div className="text-content">
            <div className="success">
              <div className="decor-top">
                <Decor type="rotate--clockwise" />
              </div>
              <p className="heading-2">Success</p>
              <p className="body-text">
                The NBA chat application launched on{" "}
                <a
                  className="underline--inline"
                  href="https://www.messenger.com/t/8245623462"
                >
                  Facebook Messenger
                </a>{" "}
                in 2019 and offers in-game video highlights, regular news
                updates, game previews and notifications, scoring alerts,
                schedules, standings and GIFs. Through the NBA chatbot, fans can
                tailor their experience around their favorite teams and players,
                when, where and how they want content delivered. By providing
                basketball fans around the world the ability to chat with the
                NBA in real-time, the NBA was able to quickly scale their reach
                on Facebook Messenger garnering over 1 million messages in less
                than a month after launch.
              </p>
              <p className="body-text">
                The NBA saw engagement from fans higher than the industry
                standards with +8% click through rate on conversational
                messaging campaigns and hundreds of hours of videos watched by
                fans on the NBA chat application.
              </p>
              <p className="body-text">
                GameOn continues to work with the NBA to refine content delivery
                algorithms and release a multitude of new features encouraging
                further fan engagement including:
              </p>
              <ul>
                <li className="bullets body-text">
                  Improvements to player notifications: Player subscriptions
                  were popular so that GameOn added a new feature so a user is
                  notified when a player they follow scores at least 40 points
                  in a game
                </li>
                <li className="bullets body-text">
                  Location based settings: Users can set their viewing location
                  so that they receive broadcast info for their region
                </li>
                <li className="bullets body-text">
                  Continued Support: Timely coverage for specific events such as
                  the All Star Game, the playoffs, and the draft
                </li>
              </ul>
            </div>
          </div>
          <div className="quote testimony-quote">
            <TestimonyQuote
              quote="The NBA chatbot provides another way for NBA fans to engage with our league when it is convenient for them. We are fortunate to work with GameOn to create a truly personalized experience."
              author="Coleen Kane"
              profileImage="/images/testimony-quote/kane.jpeg"
              position="Director Emerging Technology"
              company="NBA"
            />
          </div>

          <LastWords>
            Get in touch today
            <br className="desktop-line-break" /> for a custom chat experience
          </LastWords>

          <ContactForm action="https://formsubmit.co/df104fca19fab87fb541d94105effa78" />
        </Layout>
      </div>
    );
  }
}

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "NBA Case Study",
    metaDescription:
      "GameOn worked with the NBA to establish a new touchpoint for their fan base and create an environment where their wealth of curated knowledge is now available at a users fingertips.",
    locale: "en-US",
    slug: location.pathname,
    image: {
      url: "https://assets-dash.s3.amazonaws.com/nba-case-study-meta-img.png",
      width: 1200,
      height: 810,
    },
  };
  return <Seo {...seoMeta} />;
};

export default CaseStudy;
